<template>
	<div class="card card-custom card-stretch gutter-b" v-if="solarseason">
		<div class="card-header border-0" v-if="name">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Solar Transit Events</h3>
		</div>
		<div class="card-body px-5 pt-0">
			<div class="row">
				<div class="col-md-6">
					<p class="text-center">Remote</p>
				</div>
				<div class="col-md-6">
					<p class="text-center">Teleport</p>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6 fade-element-in">
					<table class="table table-striped table-hover">
						<thead>
							<tr>
								<td>Date</td>
								<td>Start Time (UTC)</td>
								<td>End Time (UTC)</td>
								<td>Duration (mm:ss)</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(sr, i) in solarremote" v-bind:key="i">
								<td>{{ sr[0] }}</td>
								<td>{{ sr[1] }}</td>
								<td>{{ sr[2] }}</td>
								<td>{{ sr[3] }}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="col-md-6 fade-element-in">
					<table class="table table-striped table-hover">
						<thead>
							<tr>
								<td>Date</td>
								<td>Start Time (UTC)</td>
								<td>End Time (UTC)</td>
								<td>Duration (mm:ss)</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(sr, i) in solarteleport" v-bind:key="i">
								<td>{{ sr[0] }}</td>
								<td>{{ sr[1] }}</td>
								<td>{{ sr[2] }}</td>
								<td>{{ sr[3] }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Calculate } from '@/view/content/lib/solar.js';
export default {
	name: 'SolarTransit',
	props: {
		name: String,
		solar: [Object, Boolean],
		lat: [String, Number],
		lon: [String, Number],
		solarseason: {
			default: false,
		},
	},
	data() {
		return {
			solarremote: null,
			solarteleport: null,
		};
	},
	mounted() {
		if (this.solarseason) {
			if (typeof this.solar.size !== 'undefined') {
				if (this.solar.size == 0 || this.solar.size == null) {
					// solar.js errors without antenna size
					if (this.solar.cband) {
						this.solar.size = 2.4;
					} else {
						this.solar.size = 1;
					}
					this.solar.undefinedantenna = true;
				}
				this.solarremote = eval(
					'(' +
						Calculate(
							this.lat,
							this.lon,
							this.solar.satlon,
							this.solarseason == 'Vernal' ? 1 : 0,
							this.solar.thisyear,
							this.solar.size,
							this.solar.cband
						) +
						')'
				);
			}

			if (typeof this.solar.tsize !== 'undefined') {
				if (this.solar.tsize != 0 && this.solar.tsize != null) {
					this.solarteleport = eval(
						'(' +
							Calculate(
								this.solar.tlat,
								this.solar.tlon,
								this.solar.satlon,
								this.solarseason == 'Vernal' ? 1 : 0,
								this.solar.thisyear,
								this.solar.tsize,
								this.solar.cband
							) +
							')'
					);
				}
			}
		}
	},
};
</script>

<style></style>
